.Mentorycontainer {
    background-color: #E9EED9;
    padding: 3rem 1rem;
    text-align: center;
    color: #2e2e2e;
}

.h1Mentory {
    font-size: 4vh;
    font-weight: bold;
    color: #000;
    margin: 0;
    user-select: none;
}

.h2Mentory {
    font-size: 5vh;
    color: #8b805f;
    font-family: 'Times New Roman', serif;
    margin-top: 0;
    margin-bottom: 3rem;
    user-select: none;
}

.Mentory-Container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    max-width: 100%;
    margin: 0 auto;
    padding: 1rem;
}

.Mentorycard {
    background-color: #CBD2A4;
    padding: 1.5rem;
    border: 2px solid #000;
    border-radius: 5px;
    width: calc(33.33% - 2rem); /* Ocupa un tercio del ancho menos el espacio del gap */
    height: 25vh;
    text-align: left;
    position: relative;
    transition: transform 0.3s ease;
    box-shadow: 4px 4px 0px #000;
}

.Mentorycard:hover {
    transform: translateY(-5px);
}

.Mentorycard h3 {
    font-size: 2vh;
    font-weight: bold;
    color: #000;
    margin-bottom: 0.5rem;
    user-select: none;
}

.Mentorycard p {
    font-size: 2vh;
    color: #333;
    line-height: 1.5;
    user-select: none;
}

.Mentorycard::after {
    content: '';
    display: block;
    width: 2.75rem;
    height: 2.75rem;
    background-color: #000;
    position: absolute;
    bottom: -0.625rem;
    right: -0.625rem;
    z-index: -1;
    border-radius: 30%;
}

.icon {
    font-size: clamp(1rem, 1.5vw, 1.5rem);
    margin-top: 0.5rem;
    color: #68db68;
}

/* Media Queries para dispositivos medianos */
@media (max-width: 1024px) {
    .Mentorycard {
        width: 45vw; /* Dos tarjetas en fila en pantallas medianas */
    }
}

/* Media Queries para dispositivos pequeños */
@media (max-width: 768px) {
    .Mentory-Container {
        flex-direction: column; /* Una tarjeta en cada fila en pantallas pequeñas */
        align-items: center; /* Centra las tarjetas */
    }

    .Mentorycard {
        width: 90%; /* Ocupa el 90% del ancho con margen en los lados */
        max-width: 600px; /* Limita el ancho máximo para evitar que se estire demasiado */
        margin: 0 auto; /* Centra la tarjeta */
    }
}

/* Media Queries para dispositivos muy pequeños */
@media (max-width: 480px) {
    .Mentorycard {
        width: 90%; /* Mantiene el 90% del ancho con margen en los lados */
        max-width: 400px; /* Limita el ancho máximo */
        padding: 1rem;
        margin: 0 auto; /* Centra la tarjeta */
    }
}
