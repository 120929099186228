/* Estilos generales del contenedor */
.contact-container {
    background-color: #8f887a;
    color: #fff;
    padding: 3rem;
    font-size: 2vh;
    max-width: 80vw; /* Limita el ancho máximo del contenedor */
    margin: 0 auto; /* Centra el contenedor */
    box-sizing: border-box;
}

.text-center {
    text-align: center;
    color: #00f; /* Ajusta el color primario si es necesario */
    font-size: 3vh;
}

/* Estilos de formulario y datos de contacto en pantallas grandes */
.form-section,
.contact-section {
    display: flex;
    flex-direction: column;
    max-width: 100vw; /* Limita el ancho de cada sección */
}

.form-section {
    margin-top: 2rem;
}

.contact-section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 100vw; /* Limita el ancho de la sección */
}

.contact-section p {
    display: flex;
    align-items: center;
    margin: 0.5rem 0;
    word-wrap: break-word;
}

.contact-section h3 {
    margin-top: auto;
    color: #fff;
}

.contact-section a {
    color: blue;
    text-decoration: none;
}

.contact-section a:hover {
    text-decoration: underline;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .container {
        flex-direction: column;
        align-items: center;
    }

    .form-section,
    .contact-section {
        width: 100%;
        max-width: 90%; /* Limita el ancho en pantallas medianas */
        margin-top: 1.5rem;
    }

    .form-control,
    .btn {
        font-size: 0.9rem;
        padding: 0.75rem;
        height: 10vh;
    }
}

/* Ajustes para pantallas muy pequeñas */
@media (max-width: 480px) {
    .contact-container {
        padding: 1.5rem;
    }

    .form-control,
    .btn {
        font-size: 0.8rem;
        padding: 0.5rem;
    }

    .contact-section p,
    .contact-section h4 {
        text-align: center;
        width: 100%;
    }

    .form-section,
    .contact-section {
        max-width: 100%; /* Asegura que el contenido no se salga de los márgenes */
        padding: 0 10px;
    }
}
