.testimonials-carousel {
    text-align: center;
    background-color: #E9EED9;
    padding: 20px;
    font-family: Arial, sans-serif;
}

.testimonials-carousel h2 {
    font-size: 3vh;
    color: var(--color-MarronOscuro);
    margin-bottom: 20px;
    letter-spacing: 1px;
    text-transform: uppercase;
}

.carousel-container {
    position: relative;
    display: flex;
    justify-content: center; /* Centra el contenido dentro del contenedor */
    align-items: center;
    overflow: hidden;
    width: 100%;
    max-width: 2000px;
    margin: 0 auto;
}

.video-grid {
    display: flex;
    justify-content: center; /* Centra los videos en el carrusel */
    overflow-x: scroll;
    scroll-behavior: smooth;
    gap: 20px;
    padding: 10px;
    scrollbar-width: none;
}

.video-grid::-webkit-scrollbar {
    display: none;
}

.video-thumbnail {
    position: relative;
    cursor: pointer;
    transition: transform 0.3s;
    min-width: 250px;
    max-width: 350px;
    flex: 0 0 auto;
}

.video-thumbnail img {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    height: auto;
}

.play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 50px;
    color: white;
    background-color: rgba(0, 0, 0, 0.37);
    padding: 10px;
    border-radius: 20%;
}

.video-thumbnail p {
    margin-top: 10px;
    font-size: 14px;
    color: #333;
    text-align: center;
}

.carousel-control {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 2rem;
    color: var(--color-MarronOscuro);
    background: none;
    border: none;
    cursor: pointer;
    z-index: 1;
    padding: 10px;
    transition: color 0.3s;
}

.carousel-control.left {
    left: 10px;
}

.carousel-control.right {
    right: 10px;
}

.carousel-control:hover {
    color: var(--color-MarronOscuro);
}

.close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    font-size: 16px;
    background: none;
    border: none;
    cursor: pointer;
    color: #666;
}

/* Media Queries */
@media (max-width: 768px) {
    .testimonials-carousel h2 {
        font-size: 16px;
    }
    
    .video-thumbnail {
        min-width: 200px;
        max-width: 250px;
    }
    
    .play-button {
        font-size: 40px;
    }
    
    .carousel-control {
        font-size: 1.5rem;
        top: 45%;
    }
}

@media (max-width: 480px) {
    .testimonials-carousel h2 {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .video-thumbnail {
        min-width: 180px;
        max-width: 200px;
    }
    
    .play-button {
        font-size: 30px;
    }
    
    .carousel-control {
        font-size: 1.2rem;
        top: 40%;
    }
}
