.courses-section {
    width: 100%;
    text-align: center;
    background-color: #E9EED9;
    padding-top: 2rem;
}

.courses-section h2 {
    font-size: 2rem;
    color: #3e7739;
    margin-bottom: 2rem;
}

.courses-container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
}
