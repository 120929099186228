.about-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5%;
    background-color: #000;
    color: #fff;
    min-height: 100vh;
    flex-direction: row;
    box-sizing: border-box;
}

.text-section {
    max-width: 55%; /* Limitar ancho máximo */
    padding: 20px;
}

.text-section h1 {
    font-size: 2.5vw; /* Tamaño de fuente relativo a la anchura de la ventana */
    font-weight: bold;
    margin-bottom: 20px;
    word-wrap: break-word;
    user-select: none;
}

.text-section h3 {
    font-size: 1.8vw;
    font-weight: 500;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    word-wrap: break-word;
    user-select: none;
}

.text-section h3 span {
    font-weight: bold;
    color: #72ad6a;
    margin-left: 10px;
    user-select: none;
}

.intro-text, .experience-text {
    font-size: 1.5vw;
    margin-bottom: 20px;
    line-height: 1.4;
    user-select: none;
}

.about-button {
    padding: 10px 20px;
    border: none;
    background-color: #fff;
    color: #000;
    cursor: pointer;
    font-weight: bold;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 1vw;
    user-select: none;
}

.about-button:hover {
    background-color: #72ad6a;
    color: #000;
}

.Abprofile-image {
    width: 35%;
    max-width: 30vw;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .about-container {
        padding: 4%;
        flex-direction: column;
        text-align: center;
    }
    .text-section {
        max-width: 80%;
        padding: 0;
    }
    .text-section h1 {
        font-size: 3.5vw;
    }
    .text-section h3 {
        font-size: 2.5vw;
    }
    .intro-text, .experience-text {
        font-size: 2vw;
    }
    .about-button {
        font-size: 1.5vw;
    }
    .Abprofile-image {
        width: 50%;
        max-width: 300px; /* Limita el tamaño máximo en pantallas medianas */
        margin-top: 20px; /* Añade espacio entre el botón y la imagen */
    }
}

@media (max-width: 768px) {
    .text-section h1 {
        font-size: 5vw;
    }
    .text-section h3 {
        font-size: 3.5vw;
    }
    .intro-text, .experience-text {
        font-size: 2.5vw;
    }
    .about-button {
        font-size: 2vw;
    }
    .Abprofile-image {
        width: 60%;
        max-width: 250px; /* Limita el tamaño máximo en pantallas pequeñas */
        margin-top: 30px; /* Añade más espacio para evitar que quede pegada al botón */
    }
}

@media (max-width: 480px) {
    .text-section h1 {
        font-size: 6vw;
    }
    .text-section h3 {
        font-size: 4vw;
    }
    .intro-text, .experience-text {
        font-size: 3vw;
    }
    .about-button {
        font-size: 2.5vw;
    }
    .Abprofile-image {
        width: 70%;
        max-width: 200px; /* Limita el tamaño máximo en pantallas muy pequeñas */
        margin-top: 40px; /* Añade más espacio en pantallas muy angostas */
    }
}
