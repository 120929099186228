/* Ajustes para tabletas y pantallas medianas */
@media (max-width: 1024px) {
    /* Contenedores principales */
    .Cursos-Container, .Mentory-Container, .Servicios-Container, .carousel-container, .contact-container {
        flex-direction: column;
        align-items: center;
        padding: 2rem 1rem;
        max-width: 90%;
        margin: 0 auto;
    }

    /* Texto y encabezados */
    h1, h2, h3 {
        font-size: 3vw;
    }

    /* Ajustes de tamaño de imagen */
    .profile-image, .card-img-Cursos, .card-img-Servicios {
        width: 50%;
        max-width: 100%;
    }
}

/* Ajustes para dispositivos móviles */
@media (max-width: 768px) {
    /* Contenedores principales */
    .Cursos-Container, .Mentory-Container, .Servicios-Container, .carousel-container, .contact-container {
        padding: 1rem;
        flex-direction: column;
    }

    /* Texto y encabezados */
    h1, h2, h3 {
        font-size: 4vw;
    }

    /* Ajustes de tamaño de imagen */
    .profile-image, .card-img-Cursos, .card-img-Servicios {
        width: 70%;
        max-width: 100%;
    }
}

/* Ajustes para pantallas muy pequeñas (480px o menos) */
@media (max-width: 480px) {
    .Cursos-Container, .Mentory-Container, .Servicios-Container, .carousel-container, .contact-container {
        padding: 1rem;
    }

    h1, h2, h3 {
        font-size: 5vw;
    }

    .profile-image, .card-img-Cursos, .card-img-Servicios {
        width: 90%;
    }
}
