.nav-link {
    position: relative;
    padding: 8px 16px;
    color: rgb(95, 70, 39);
    background-color: transparent;
    transition: background-color 0.3s ease, color 0.3s ease;
    border-radius: 8px;
    text-decoration: none;
    outline: none;
    z-index: 1;
    user-select: none;
    font-size: 2vh;
    font-weight: 600;
}

.nav-link:hover {
    background-color: rgb(85, 139, 83);
    color: white;
}

.nav-link:focus,
.nav-link:active {
    color: black;
    background-color: transparent;
    outline: none;
    box-shadow: none;
}

.logoimg {
    user-select: none;
}

.mainPage {
    background-color: #cccccc;
}

.no-margin-padding {
    margin: 0;
    padding: 0;
}