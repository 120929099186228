/* Contenedor principal */
.containerTuguederSecondary {
    position: relative;
    width: 100%;
    height: 100vh; /* Ocupa toda la altura visible de la ventana */
    background-color: black; /* Fondo negro */
    overflow: hidden; /* Oculta las estrellas que se salen del contenedor */
  }
  
  /* Texto centrado */
  .overlayTuguederSecondary {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); /* Centra el texto */
    text-align: center;
    color: white;
    font-size: 2rem;
    font-weight: bold;
    z-index: 2; /* Se asegura de que el texto esté por encima de las estrellas */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Sombra en el texto */
  }
