/* Contenedor principal */
.containerTugueder {
    position: relative;
    width: 100%;
    height: 100vh; /* Ocupa toda la ventana visible */
    margin: 0;
    padding: 0;
    overflow: hidden;
}

/* Video de fondo */
.background-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* El video se adapta al contenedor */
    z-index: 1; /* Coloca el video detrás del contenido */
}

/* Superposición del contenido */
.overlayTugueder {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column; /* Coloca texto y otros elementos en una columna */
    justify-content: center; /* Centra el contenido verticalmente */
    align-items: center; /* Centra el contenido horizontalmente */
    background-color: rgba(0, 0, 0, 0.5); /* Oscurece ligeramente el fondo */
    text-align: center;
    color: white;
    z-index: 1; /* Coloca la superposición encima del video */
}

/* Estilo del título */
.overlayTugueder h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 30px; /* Espacio entre el título y otros elementos */
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8); /* Sombra en el texto */
}
