.course-card {
    background-color: #CBD2A4;
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 4px 4px 0px #000;
    text-align: center;
    padding: 1rem;
    width: 20vw; /* Controla el tamaño mínimo y máximo */
    max-width: 300px; /* Limita el ancho máximo en pantallas grandes */
    min-width: 250px; /* Limita el ancho mínimo en pantallas pequeñas */
}

.new-badge {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: #d32f2f;
    color: #fff;
    padding: 0.3rem 0.5rem;
    border-radius: 5px;
    font-size: 0.8rem;
    font-weight: bold;
}

.course-image {
    width: 100%; /* Ocupa el 100% del ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 10px;
    margin-bottom: 1rem;
    object-fit: cover; /* Mantiene la proporción sin deformar la imagen */
    user-select: none;
}

.course-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw; /* Controla el ancho máximo y mínimo */
    text-align: center;
}

.course-card h3 {
    font-size: 3vh;
    color: #574e3d;
    font-weight: 500;
    margin-top: 0;
    user-select: none;
}

.button-container {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    background-color: #E9EED9;
}

.course-button {
    background-color: #6A8D73;
    color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 3vh; /* Tamaño adaptable del texto del botón */
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}