.Cursos-header {
    text-align: center;
    background-color: #E9EED9;
    padding: 2rem;
    font-weight: bold;
    font-size: clamp(1.2rem, 1.5vw, 1.5rem); /* Ajusta el tamaño de fuente */
}

.Cursos-header p {
    margin: 0.5rem 0;
    font-size: 4vh;
    color: #000000;
}

.Cursos-button {
    background-color: #72ad6a;
    color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    margin-top: 1rem;
    cursor: pointer;
    font-size: 3vh; /* Tamaño adaptable */
    border-radius: 5px;
}

.Cursos-Container {
    display: flex;
    justify-content: center;
    padding: 2rem;
    background-color: #E9EED9;
}

.Cursos-card {
    background-color: #CBD2A4;
    border: 2px solid #000;
    border-radius: 5px;
    box-shadow: 4px 4px 0px #000;
    text-align: center;
    padding: 1rem;
    width: 20vw; /* Controla el tamaño mínimo y máximo */
    max-width: 300px; /* Limita el ancho máximo en pantallas grandes */
    min-width: 250px; /* Limita el ancho mínimo en pantallas pequeñas */
}

.Cursos-card h2, .Cursos-card h3 {
    color: #4b4239;
    font-size: 3vh;
    margin-bottom: 0.5rem;
}

.card-img-Cursos {
    width: 100%; /* Ocupa el 100% del ancho del contenedor */
    height: auto; /* Mantiene la proporción de la imagen */
    border-radius: 10px;
    margin-bottom: 1rem;
    object-fit: cover; /* Mantiene la proporción sin deformar la imagen */
}

.info-button {
    background-color: #72ad6a;
    color: #fff;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    font-size: 3vh; /* Tamaño adaptable */
    border-radius: 5px;
}

/* Media queries para pantallas medianas y pequeñas */
@media (max-width: 768px) {
    .Cursos-Container {
        flex-direction: column; /* Alinea las tarjetas en columna en pantallas pequeñas */
        align-items: center;
    }

    .Cursos-card {
        width: 90%; /* Ocupa el 90% del ancho del contenedor en pantallas pequeñas */
        max-width: 400px; /* Limita el ancho máximo en dispositivos pequeños */
        padding: 1rem;
        margin: 1rem 0; /* Añade margen entre tarjetas */
    }

    .card-img-Cursos {
        max-width: 100%; /* Evita que la imagen se haga más pequeña que su contenedor */
        height: auto; /* Mantiene la proporción de la altura */
    }
}

@media (max-width: 480px) {
    .Cursos-card {
        width: 100%; /* Ocupa el 100% del ancho en dispositivos muy pequeños */
        max-width: 350px;
        padding: 1rem;
    }
}
