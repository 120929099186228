.navbarTugueder {
    font-family: Arial, sans-serif;
    background-color: rgba(0, 0, 0, 0); /* Fondo negro semitransparente */
    color: white;
    padding: 10px 20px;
    position: fixed; /* Fija el navbar en la parte superior */
    top: 0;
    left: 0;
    width: 100%; /* Ocupa todo el ancho de la pantalla */
    z-index: 1000; /* Asegura que esté sobre otros elementos */
}

.navbarTugueder-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.navbarTugueder-logo {
    flex: 0 0 auto; /* Mantiene el logo a la izquierda */
    margin-right: 20px;
}

.navbarTugueder-menu {
    list-style: none;
    display: flex;
    gap: 15px;
    margin: 0 auto; /* Centra el texto del menú */
    padding: 0;
    flex: 1;
    justify-content: center; /* Centra los textos en la barra */
}

.navbarTugueder-menu li {
    display: inline-block;
}

.navbarTugueder-menu li a {
    text-decoration: none;
    color: white;
    font-size: 14px;
    transition: color 0.3s ease;
}

.navbarTugueder-menu li a:hover {
  color: #f0a500; /* Color de hover para destacar */
}

.navTugueder-link {
    margin: 50px;
}