.Servicios-header {
    text-align: center;
    color: #9e8155;
    font-weight: bold;
    font-size: 5vh; /* Controla el tamaño en diferentes vistas */
    padding: 1.5rem 0;
    user-select: none;
    background-color: #E9EED9;
}

.Servicios-Container {
    display: flex;
    justify-content: center;
    gap: 2rem;
    flex-wrap: wrap;
    padding: 1rem 2rem;
    background-color: #E9EED9;
    max-width: 100%;
}

.Service-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 20vw; /* Controla el ancho máximo y mínimo */
    text-align: center;
}

.card-img-Servicios {
    width: 100%; /* Permite que la imagen ocupe el 100% del ancho del contenedor */
    height: auto; /* Mantiene la proporción de altura automáticamente */
    max-width: 200px; /* Define un ancho máximo para pantallas grandes */
    max-height: 300px; /* Define un alto máximo para mantener la proporción */
    object-fit: cover; /* Mantiene la proporción de la imagen y la recorta si es necesario */
    margin-bottom: 1rem;
    user-select: none;
}

.Service-card h3 {
    font-size: 3vh;
    color: #569446;
    font-weight: 500;
    margin-top: 0;
    user-select: none;
}

.button-container {
    display: flex;
    justify-content: center;
    padding: 1.5rem 0;
    background-color: #E9EED9;
}

.Servicios-button {
    background-color: #9e8155;
    color: #fff;
    border: none;
    padding: 0.5rem 1.5rem;
    font-size: 3vh; /* Tamaño adaptable del texto del botón */
    cursor: pointer;
    border-radius: 5px;
    user-select: none;
}

/* Media queries para pantallas medianas y pequeñas */
@media (max-width: 768px) {
    .Service-card {
        width: 90%; /* Aumenta el ancho para dispositivos pequeños */
        max-width: 400px; /* Limita el ancho máximo */
    }

    .card-img-Servicios {
        max-width: 100%; /* Ajusta el ancho máximo al contenedor en pantallas pequeñas */
        height: auto; /* Mantiene la proporción de la altura */
    }
}

@media (max-width: 480px) {
    .Service-card {
        width: 100%; /* Aumenta el ancho al 100% en pantallas muy pequeñas */
    }
}
