.container {
    position: relative;
    background-color: #cccccc;
    width: 100%;
    height: 80vh;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: row; /* Imagen fija a la izquierda */
    overflow: hidden;
    user-select: none; /* Evita selección de elementos en la caja */
}

.profile-image {
    width: 35%;
    max-width: 30vw;
    border-radius: 10px;
    margin: 0 auto;
    display: block;
    position: absolute;
    pointer-events: none; /* Evita selección de imagen */
    user-select: none; /* Evita selección de imagen */
}

.Principaltext-content {
    z-index: 2;
    max-width: 50%;
    text-align: left;
    word-wrap: break-word;
    margin-left: 35vw;
    margin-bottom: 20vh;
    user-select: none; /* Evita selección de texto */
}

.Principaltext-content h1 {
    font-size: 2.5vw;
    font-weight: bold;
    color: #2b2c2b;
    white-space: normal;
    margin: 0;
    line-height: 1.2;
    user-select: none; /* Evita selección de texto */
}

/* Estilos responsivos para pantallas medianas */
@media (max-width: 1024px) {
    .profile-image {
        width: 45vw;
        max-width: 250px;
    }

    .Principaltext-content {
        max-width: 60%;
        margin-left: 50vw; /* Ajusta la posición del texto */
        margin-bottom: 15vh;
    }

    .Principaltext-content h1 {
        font-size: 3vw;
    }
}

/* Reorganización para pantallas más pequeñas */
@media (max-width: 768px) {
    .container {
        flex-direction: column; /* Cambia la dirección a columna en pantallas pequeñas */
        align-items: center;
        justify-content: center;
    }

    .profile-image {
        width: 50vw;
        max-width: 200px;
        margin: 10px 0;
    }

    .Principaltext-content {
        max-width: 80%;
        margin-left: 0;
        text-align: center;
        margin-bottom: 10vh;
    }

    .Principaltext-content h1 {
        font-size: 4vw;
    }
}

/* Estilos para pantallas muy pequeñas */
@media (max-width: 480px) {
    .profile-image {
        width: 60vw;
        max-width: 150px;
    }

    .Principaltext-content {
        max-width: 90%;
        margin-left: 0;
        text-align: center;
        padding: 0 10px;
    }

    .Principaltext-content h1 {
        font-size: 5vw;
    }
}
