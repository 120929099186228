.course-container {
    display: flex;
    justify-content: space-between;
    font-family: Arial, sans-serif;
    padding: 20px;
    color: #333;
}

.course-info {
    width: 60%;
}

.course-info h1 {
    font-size: 24px;
    margin-bottom: 10px;
}

.course-info p {
    font-size: 16px;
    margin-bottom: 20px;
}

.course-meta {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 20px;
}

.badge {
    background-color: #ff4b2b;
    color: #fff;
    padding: 4px 8px;
    border-radius: 4px;
}

.course-tabs {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 10px;
}

.course-tabs a {
    text-decoration: none;
    color: #333;
    font-weight: bold;
}

.course-video {
    margin-bottom: 20px;
}

.course-image {
    width: 100%;
    border-radius: 8px;
}

.course-description h2 {
    font-size: 20px;
    margin-bottom: 10px;
}

.course-sidebar {
    width: 35%;
    background-color: #f8f8f8;
    padding: 20px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.price-box {
    text-align: center;
    margin-bottom: 20px;
}

.price {
    font-size: 24px;
    font-weight: bold;
    color: #00b3a4;
}

.discount {
    color: #ff4b2b;
    font-size: 16px;
}

.original-price {
    text-decoration: line-through;
    color: #999;
}

.buy-button {
    background-color: #00b3a4;
    color: #fff;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 16px;
}

.buy-button:hover {
    background-color: #008f8f;
}

.offer-timer {
    font-size: 14px;
    color: #ff4b2b;
    margin-top: 10px;
}

.regular-price h3 {
    font-size: 16px;
    margin-bottom: 5px;
}

.regular-price span {
    font-size: 18px;
    color: #333;
}

.additional-info {
    font-size: 14px;
    color: #666;
    text-align: left;
    width: 100%;
    margin-top: 20px;
    line-height: 1.6;
}

.gift-button {
    background-color: #fff;
    color: #00b3a4;
    border: 2px solid #00b3a4;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 20px;
    font-size: 16px;
}

.gift-button:hover {
    background-color: #00b3a4;
    color: #fff;
}
