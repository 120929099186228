.CursosNavbar {
    position: absolute; /* Fija el navbar en la parte superior */
    top: 0; /* Alinea el navbar en la parte superior */
    left: 0; /* Alinea el navbar al borde izquierdo */
    right: 0; /* Alinea el navbar al borde derecho */
    width: 100%; /* Ocupa todo el ancho disponible */
    z-index: 1000; /* Asegura que esté encima de otros elementos */
    background-color: #f8f9fa; /* Color de fondo, ajusta según tu diseño */
    padding: 10px 0; /* Espacio interno en el navbar */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Sombra para diferenciarlo del contenido */
}
.content-container {
    margin-top: 120px; /* Ajusta este valor según la altura de tu navbar */
}
