.content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    background-color: #E9EED9;
}

.text-image-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: 1200px;
    gap: 2rem;
    margin-bottom: 3rem;
}

@media (max-width: 768px) {
    .text-image-container {
        flex-direction: column;
        text-align: center;
    }
}
