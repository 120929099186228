.header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
}

.text-button-container {
    flex: 1;
    max-width: 60%;
}

.image-container {
    flex: 1;
    max-width: 40%;
    display: flex;
    justify-content: flex-end;
}

.image-container img {
    max-width: 100%;
    height: auto;
}


.header-content h1 {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 1rem;
}

.header-content p {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 1rem;
}

.button {
    background-color: #7b44d1;
    color: white;
    padding: 0.8rem 1.5rem;
    border: none;
    border-radius: 5px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s;
}

.button:hover {
    background-color: #5a32a5;
}
