body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


:root {
  --color-BeigeArena: #C2B280 ;
  --color-BeigeClaro: #D8C3A5 ;
  --color-MarronCafe: #6F4E37 ;
  --color-MarronOscuro: #403D39 ;
  --color-VerdeMusgo: #3D4A2A ;
  --color-VerdeClaro: #6A8D73 ;
  --color-VerdeOscuro: #3C6E71 ;
  --color-AzulOceano: #4682B4 ;
  --color-AzulOscuro: #284B63 ;
  --color-GrisPizarra: #4A4A4A ;
}
